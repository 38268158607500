import { useState, useEffect } from 'react'

import { useProposal, useLead } from '@itau-loans-www/shopping/src/hooks'
import { goTo, formatters, storage } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import { purposeDictionary } from './constants'
import services from './services'

const usePurposeData = () => {
  const { number } = formatters
  const { changeLeadData } = useLead()

  const { setIsLoading } = useProposal()
  const [onLoadData, setOnLoadData] = useState({
    prevStep: 'purpose-data',
    desiredLoanValue: 0
  })

  useEffect(() => {
    const getData = async () => {
      try {
        const { previous_step, desired_loan_value } =
          await services.getPurposeData()
        changeLeadData({
          previousStep: previous_step
        })
        setOnLoadData({
          prevStep: previous_step,
          desiredLoanValue: number.toCurrency(desired_loan_value ?? 0)
        })
      } catch (error) {
        const payload = {
          error: error?.message,
          requestURL: error?.request?.responseURL
        }

        DDPageError({
          name: 'usePurposeData | getData',
          payload
        })
      } finally {
        setIsLoading(false)
      }
    }

    getData()
    console.log('purpose mounted')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading])

  const onSubmit = async (payload) => {
    setIsLoading(true)

    const { purpose } = payload

    const translatedPurpose = purposeDictionary[purpose]
    storage.session.setItem('ga_purpose', translatedPurpose)

    const requestBody = {
      purpose
    }

    return services
      .putPurposeData(requestBody)
      .then(({ next_step }) => goTo(next_step))
      .catch((error) => {
        const payload = {
          error: error?.message,
          requestURL: error?.request?.responseURL
        }

        DDPageError({
          name: 'usePurposeData | onSubmit',
          payload
        })
        setIsLoading(false)
      })
  }

  return { onSubmit, onLoadData }
}

export default usePurposeData
