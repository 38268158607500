import { useForm } from 'react-hook-form'

import { Navigation, Radio } from '@itau-loans-www/shopping/src/components'
import { submitOnChange } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from 'layouts/Proposal'
import { goTo } from 'utils'

import { purposeData } from './constants'
import usePurposeData from './hooks'

const PurposeData = ({ pageContext }) => {
  const initialValues = {
    purpose: ''
  }

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const { onSubmit, onLoadData } = usePurposeData()

  return (
    <ProposalLayout pageContext={pageContext}>
      <h1 className="proposal-title">E como esse dinheiro vai te ajudar?</h1>
      <form className="form">
        <Radio
          id="purpose"
          name="purpose"
          data-cy="cy-purpose"
          options={purposeData}
          colClassName="card-column"
          grid={{
            mobile: 6,
            tablet: 6,
            desktop: 6
          }}
          card
          rules={{
            isRequired: true
          }}
          control={control}
          onChange={() => submitOnChange(handleSubmit(onSubmit))}
        />
        <Navigation
          prevStep={onLoadData.prevStep}
          prevButtonId="purpose-data-prev-btn"
          nextButtonId="purpose-data-next-btn"
          onPrevStepClick={() => goTo(onLoadData.prevStep)}
        />
      </form>
    </ProposalLayout>
  )
}

export default PurposeData
